require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyCMpY94TB5zv4IMh3gecqfqwzBU8qd3OV4",
  authDomain: "wbtankrestaurant.firebaseapp.com",
  projectId: "wbtankrestaurant",
  storageBucket: "wbtankrestaurant.appspot.com",
  messagingSenderId: "432840609961",
  appId: "1:432840609961:web:61e1d1cbb0fb457d21d9b8",
  measurementId: "G-YVFY90SXBY"
};





  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };