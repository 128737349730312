import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: { pageTitle: "Stacja smaku", title: 'Stacja smaku' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Dashboard.vue'),
      //   component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'uzytkownicy',
        component: () => import(/* webpackChunkName: "about" */ '../views/ManageUsers.vue'),
        meta: { pageTitle: "Zarządzaj kontem", title: 'Twoje konto' }
      },
      {
        path: 'koszyk',
        component: () => import(/* webpackChunkName: "about" */ '../views/Cart.vue'),
        meta: { pageTitle: "Zarządzaj koszykiem", title: 'Twój koszyk' }
      },
      {
        path: 'zamowienia',
        component: () => import(/* webpackChunkName: "about" */ '../views/Orders.vue'),
        meta: { pageTitle: "Lista zamówień", title: 'Wszystkie zamówienia' }
      },
      {
        path: 'zamowienie',
        component: () => import(/* webpackChunkName: "about" */ '../views/OrderPage.vue'),
        meta: { pageTitle: "Zarządzaj zamówieniem", title: 'Zarządzaj zamówieniem' }
      },
      {
        path: 'klient/zamowienie',
        component: () => import(/* webpackChunkName: "about" */ '../views/OrderPageForClients.vue'),
        meta: { pageTitle: "Podgląd zamówienia", title: 'Podgląd zamówienia' }
      },
      {
        path: 'kaucje',
        component: () => import(/* webpackChunkName: "about" */ '../views/DepositItems.vue'),
        meta: { pageTitle: "Zarządzaj produktami podlegającym kaucji", title: 'Zarządzaj produktami podlegającym kaucji' }
      },
      {
        path: 'produkty_dodatkowe',
        component: () => import(/* webpackChunkName: "about" */ '../views/AdditionalProducts.vue'),
        meta: { pageTitle: "Zarządzaj produktami dodatkowymi", title: 'Zarządzaj produktami dodatkowymi' }
      },
      {
        path: 'produkty_zestawowe',
        component: () => import(/* webpackChunkName: "about" */ '../views/SetProducts.vue'),
        meta: { pageTitle: "Zarządzaj produktami zestawowymi", title: 'Zarządzaj produktami zestawowymi' }
      },
      {
        path: '/edycja/galeria',
        component: () => import(/* webpackChunkName: "about" */ '../views/GalleryEdit.vue'),
        meta: { pageTitle: "Zarządzaj zdjęciami w galerii", title: 'Zarządzaj zdjęciami w galerii' }
      },
      {
        path: '/galeria',
        component: () => import(/* webpackChunkName: "about" */ '../views/ImageGallery.vue'),
        meta: { pageTitle: "Zdjęcia w galerii", title: 'Zdjęcia w galerii' }
      },
      {
        path: '/galeria',
        component: () => import(/* webpackChunkName: "about" */ '../views/ImageGallery.vue'),
        meta: { pageTitle: "Zdjęcia w galerii", title: 'Zdjęcia w galerii' }
      },
      {
        path: '/kalendarz',
        component: () => import(/* webpackChunkName: "about" */ '../views/Calendar.vue'),
        meta: { pageTitle: "Kalendarz", title: 'Kalendarz' }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
