import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import axios from 'axios';
import Cookies from 'js-cookie'


export default createStore({
  state: {
    cart: null,
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    orderSupportNotification: {
      show: false,
      name: "",
      imageUrl: "",
      phone: "",
      mail: "",
      orderId: ""
    },
    additionalProducts: [],
    user: null,
    userData: null,
    authIsReady: false,
    globalLoader: {
      show: false,
      head: "Loading..",
      subheader: "Don't close this tab."
    },
  },
  mutations: {
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setOrderSupportNotification(state, notification)
    {
      state.orderSupportNotification = notification;
      // setTimeout(()=>
      // {
      //   state.orderSupportNotification = {
      //     show: false,
      //     name: "",
      //     phone: "",
      //     mail: "",
      //     orderId: ""
      //   }
      // },10000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    // Set user to Vuex store
    async setUser(state, userData)
    {
      state.user = userData;
      if(userData !== null){
        let userCart = await db.collection("UncompletedCarts").doc(`cart-${state.user.uid}`).get();
        if(userCart.exists)
        {
          state.cart = userCart.data()
        }
        else
        {
          state.cart = null;
        }
        
      }
    },
    updateInternetConnectionStatus(state, status)
    {
      state.internetConnection = status;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Ładowanie..",
        subheader: "Nie zamykaj tego okna."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },

  },
  actions: {
    //login new user
    async login(context,payload)
    {
      const signIn = await auth.signInWithEmailAndPassword(payload.email, payload.password);
      if (signIn) {
        const userCredentials = await db.collection("UsersData").doc(signIn.user.uid).get();
        if(userCredentials.exists){
          context.commit('setGlobalLoader',{
            show: true,
            head: "Sprawdzanie twoich danych..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        context.commit('setUserCredentials', userCredentials.data());
        context.commit('setUser', signIn.user);
        }else{
          throw new Error("Could not complete login action.")
        }
          
      } else {
        throw new Error("Could not complete login action.")
      }
    },
    // logout user;
    async logout(context)
    {
      context.state.authIsReady = false;
      await auth.signOut();
      context.commit('setUser', null);
      context.commit('setUserCredentials', null);
      context.commit("resetGlobalLoader");
    },
    deleteMeal(context, name)
    {
      for(let i=context.state.cart.positions.length-1; 0<=i; i--)
      {
        if(context.state.cart.positions[i].meal === name)
        {
          context.state.cart.positions.splice(i,1)
        }
      }
      for(let i=0; i<context.state.cart.meals.length; i++ )
      {
        if(context.state.cart.meals[i] === name)
        {
          context.state.cart.meals.splice(i,1);
        }
      }
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
    },
    addNewMeal(context, mealName)
    {
      context.state.cart.meals.push(mealName);
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
    },
    createCart(context, payload)
    {
      context.state.cart = {
        type: payload.type,
        meals: payload.meals,
        positions: []
      }
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).set(context.state.cart);
    },
    addAdditionalProduct(context, payload)
    {
      context.state.additionalProducts.push(payload)
      Cookies.set(`additionalProd-${context.state.user.uid}`, JSON.stringify(context.state.additionalProducts), { expires: 1 });
    },
    spliceAdditionalProduct(context, indx)
    {
      context.state.additionalProducts.splice(indx);
      Cookies.set(`additionalProd-${context.state.user.uid}`, JSON.stringify(context.state.additionalProducts), { expires: 1 });
    },
    clearAdditionalProduct(context)
    {
      context.state.additionalProducts = [];
      Cookies.set(`additionalProd-${context.state.user.uid}`, JSON.stringify(context.state.additionalProducts), { expires: 1 });
    },
    setAdditionalProduct(context,payload)
    {
      context.state.additionalProducts = payload;
      Cookies.set(`additionalProd-${context.state.user.uid}`, JSON.stringify(context.state.additionalProducts), { expires: 1 });
    },
    addToCart(context, payload)
    {
      let cartProduct = context.state.cart.positions.find((product) => product.meal === payload.meal && product.id === payload.id);
      if(cartProduct)
      {
        let cartObject = {}
        let payloadaObject = {}
        for (let index = 0; index < cartProduct.product.slots.length; index++) {
          const elementFromCart = cartProduct.product.slots[index].product.setProductName;
          if(cartObject[elementFromCart] === undefined){
            cartObject[elementFromCart] = 1
          }else{
            cartObject[elementFromCart]++
          }
        }
        if(payload.product.slots === undefined){
          payload.product.slots = []
        }
        for (let index2 = 0; index2 < payload.product.slots.length; index2++) {
          const elementFromPayload =  payload.product.slots[index2].product.setProductName;
          if(payloadaObject[elementFromPayload] === undefined){
            payloadaObject[elementFromPayload] = 1
          }else{
            payloadaObject[elementFromPayload]++
          }
        }

        let isSame = true
        for (let i = 0; i < Object.keys(cartObject).length; i++) {
          const elementFromCart = Object.keys(cartObject)[i];
          if(cartObject[elementFromCart] !== payloadaObject[elementFromCart]){
            isSame = false
          }
        }

        if(isSame){
          cartProduct.quantity += payload.product.pickedAmount;
          cartProduct.price += parseFloat((payload.product.unitPrice * payload.product.pickedAmount).toFixed(2));
        }else{
          context.state.cart.positions.push({
            meal: payload.meal,
            id: payload.id,
            product: {
              productType: payload.product.productType !== undefined ? payload.product.productType : 'standard',
              productName: payload.product.productName,
              availableSetProducts: payload.product.availableSetProducts !== undefined ? payload.product.availableSetProducts : [],
              slots: payload.product.slots !== undefined ? payload.product.slots : [],
              description: payload.product.description,
              imageRef: payload.product.imageRef,
              imageUrl: payload.product.imageUrl,
              createdDate: payload.product.createdDate,
              unitPrice: payload.product.unitPrice,
              unitDiscount: 0,
            },
            price: parseFloat((payload.product.unitPrice * payload.product.pickedAmount).toFixed(2)),
            quantity: payload.product.pickedAmount,
          });
        }
      }
      else
      {
        context.state.cart.positions.push({
          meal: payload.meal,
          id: payload.id,
          product: {
            productType: payload.product.productType !== undefined ? payload.product.productType : 'standard',
            productName: payload.product.productName,
            availableSetProducts: payload.product.availableSetProducts !== undefined ? payload.product.availableSetProducts : [],
            slots: payload.product.slots !== undefined ? payload.product.slots : [],
            description: payload.product.description,
            imageRef: payload.product.imageRef,
            imageUrl: payload.product.imageUrl,
            createdDate: payload.product.createdDate,
            unitPrice: payload.product.unitPrice,
            unitDiscount: 0,
          },
          price: parseFloat((payload.product.unitPrice * payload.product.pickedAmount).toFixed(2)),
          quantity: payload.product.pickedAmount,
        });
      }
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });

    },
    deleteFromCart(context, payload)
    {
      let cartProductIndex = context.state.cart.positions.findIndex((product) => product.meal === payload.meal && product.id === payload.id);
      context.state.cart.positions[cartProductIndex].price -= parseFloat((payload.product.unitPrice * context.state.cart.positions[cartProductIndex].quantity).toFixed(2));
      context.state.cart.positions.splice(cartProductIndex, 1);
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
    },
    removeFromCart(context, payload)
    {
      let cartProductIndex = context.state.cart.positions.findIndex((product) => product.meal === payload.meal && product.id === payload.id);
      context.state.cart.positions[cartProductIndex].quantity--;
      context.state.cart.positions[cartProductIndex].price -= payload.product.unitPrice;
      if(context.state.cart.positions[cartProductIndex].quantity === 0)
      {
        context.state.cart.positions.splice(cartProductIndex, 1);
      }
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
    },
    addSingleToCart(context, payload)
    {
      let cartProductIndex = context.state.cart.positions.findIndex((product) => product.meal === payload.meal && product.id === payload.id);
      context.state.cart.positions[cartProductIndex].quantity++;
      context.state.cart.positions[cartProductIndex].price += payload.product.unitPrice;
      // Cookies.set(`cart-${context.state.user.uid}`, JSON.stringify(context.state.cart), { expires: 1 });
      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).update(context.state.cart);
    },
    clearWholeCart(context)
    {
      context.state.cart = null;
      context.state.additionalProducts = []
      // if(Cookies.get(`cart-${context.state.user.uid}`) !== undefined)
      // {
      //   Cookies.remove(`cart-${context.state.user.uid}`)
      // }
      if(Cookies.get(`additionalProd-${context.state.user.uid}`) !== undefined)
      {
        Cookies.remove(`additionalProd-${context.state.user.uid}`);
      }

      db.collection("UncompletedCarts").doc(`cart-${context.state.user.uid}`).delete()
    }
  },
  modules: {
  }
})
